import React from 'react';
import {MyContext} from '../context/AppContext';
import '../styles/components/Login.css';
import ModalRegister from './ModalRegister';
import logoLogin from "../images/dustLogin.webp";
import logo from '../images/logo-blanco.png';
import iconClose from "../images/Icon-close.webp";
import Loading from './Loading';
import {GTMDataLayerPusher} from './Analytics/TagManager';

const urlValidateUser = "https://backend.dust2.gg/wp-json/jwt-auth/v1/token";
const ModalLogin = ({status}) => {
    // const API_CUSTOMER = process.env.REACT_APP_CRM_BASE_URL + "/customer/";
    const API_CUSTOMER = "https://crm.dust2.gg/api/woocommerce/customer";
    const { registerModal, modalRegister, modalLogin, addUserLogin } = React.useContext(MyContext);
    const [loading, setLoading] = React.useState(false); 
    const closeModalLogin = () => {
        modalLogin(false);
    }
    const handleOnSubmit = (e) => { 
        e.preventDefault();
        const data = new FormData(e.target);
        const loginFields = Object.fromEntries(data.entries());
        // console.log(loginFields);
        setLoading(true);
        (async function fetchUser() {
            fetch(API_CUSTOMER + "?email=" + loginFields.email)
                .then(res => res.json())
                .catch(error => console.error('Error:', error))
                .then(response => {
                    if (response) {
                        fetch(urlValidateUser, {
                            method: 'POST', // or 'PUT'
                            body: JSON.stringify({username: response.username, password: loginFields.password}),
                            headers:{
                              'Content-Type': 'application/json'
                            }
                            })
                            .then(res => res.json())
                            .catch(error => console.error('Error:', error))
                            .then(res => {
                                if (res.data) {
                                    setLoading(false);
                                    // document.querySelector(".Content__Register").classList.add("registerActive");
                                    // setTimeout(() => {
                                    //     document.querySelector(".Content__Register").classList.remove("registerActive");
                                    // }, 3000);
                                    document.querySelector("p#validateLoginPassword").innerHTML = res.message.replace("<a href=\"https://backend.dust2.gg/mi-cuenta/lost-password/\">¿Has olvidado tu contraseña?</a>", "");
                                }else {
                                    addUserLogin(response);
                                    const gtmDataLogin = {
                                        event: "login",
                                        pagePath: "/cuenta",
                                        pageTitle: "Cuenta",
                                        additionalData: {
                                            method: "web",
		                                    userId:  response.id
                                        }
                                    };
                                    GTMDataLayerPusher(gtmDataLogin);
                                    document.querySelector(".Content__Register").classList.add("registerActive");
                                    setTimeout(() => {
                                        document.querySelector(".Content__Register").classList.remove("registerActive");
                                    }, 5000);
                                    setLoading(false);
                                    modalLogin(false);
                                   
                                }
                                
                        }) 
                    }else {
                        setLoading(false);
                        document.querySelector("p#validateLoginPassword").innerHTML = "El correo electrónico no se encuentra en nuestro sistema!";
                    }
                })
            })();
        // (async function fetchUser() {
        //     fetch(urlValidateUser, {
        //         method: 'POST', // or 'PUT'
        //         body: JSON.stringify(loginFields),
        //         headers:{
        //           'Content-Type': 'application/json'
        //         }
        //       }).then(res => res.json())
        //       .catch(error => console.error('Error:', error))
        //       .then(response => {
        //         console.log(response.message)
        //       })
        // })();
    }
    const openModalRegister = () => {
        const gtmData= {
            event: "view_promotion",
            pagePath: "/",
            pageTitle: "Vista de promocion",
            additionalData: {
                ecommerce: {
                    coupon: "bienvenidoadust2"
                }
            }
        };
        GTMDataLayerPusher(gtmData);
        modalRegister(true);
        document.querySelector(".Content__login.loginActive").classList.remove('loginActive');

    }
    return (
        <>
        <div class={status ? "Content__login loginActive" : "Content__login"}>
            {!!loading && (
                <Loading position="absolute" width={"185px"}> 
                    <h3>Iniciando Sesión...</h3>
                </Loading>
            )}
            <div class="container">
                <div class="Content__login--container">
                    <div class="Content__login--top">
                        <div class="Content__login--top-logo">
                            <div class="login__top--logo-dusito">
                                <img src={logoLogin} alt="" />
                            </div>
                            <div class="login__top--logo-image">
                                <h4>Bienvenidos a mi mundo, soy Dusito</h4>
                                <img src={logo} alt=""/>
                            </div>
                        </div>
                        <div class="Content__login--top-close" onClick={()=> {closeModalLogin()}}>
                            <img src={iconClose} alt="" />
                        </div>
                    </div>
                    <div class="Content__login--form">
                        <form onSubmit={(e) => {handleOnSubmit(e)}}>
                            <div class="Content__login--form-input">
                                <label for="email">Correo Electrónico</label>
                                <input type="text" name="email" id="email" placeholder="example@correo.cl" />
                                <p id='validateLoginEmail'></p>
                            </div>
                            <div class="Content__login--form-input">
                                <label for="password">Contraseña</label>
                                <input type="password" name="password" id="password" placeholder="Password" />
                                <p id='validateLoginPassword'></p>
                            </div>
                            <div class="Content__login--form-radioButton">
                                <input type="radio" name="rememberMe" id="rememberMe" placeholder="rememberMe" />
                                <label for="rememberMe">
                                    <span>Recuérdame</span>
                                </label>
                            </div>
                            <div class="Content__login--form-button">
                                <button>INGRESAR</button>
                            </div>
                        </form>
                    </div>
                    <div class="Content__login--bottom">
                        <div class="Content__login--bottom-register">
                            <h4>¿Aún no estás registrado?</h4>
                            <a class="openRegister" onClick={openModalRegister}>Regístrate Aquí</a>
                        </div>
                        <div class="Content__login--bottom-recoverPassword">
                            <h4>¿Olvidaste tu contraseña?</h4>
                            <a href="/recovery-password/">Recúperala Aquí</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        {!!registerModal.payload && (
            <>
            <ModalRegister />
            </>
        )}
        {/* {gtmData && <GTMDataLayerPusher {...gtmData} />} */}
        </>
        
    );
}

export default ModalLogin;
