import React from 'react';
const getLocalStorageItem = (key) => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
    return null;
};

    const dataProductsCart = getLocalStorageItem("products_cart");
    const dataBillingCheckout = getLocalStorageItem("billing_checkout");
    const dataUserLogin = getLocalStorageItem("user_login");
    const dataCouponCode = getLocalStorageItem("all_data_coupon");

const initialState = {
    cart: JSON.parse(dataProductsCart) ?? [],
    product: [],
    billingCheckout: JSON.parse(dataBillingCheckout) ?? [],
    user: JSON.parse(dataUserLogin) ?? [],
    shippingLines: {
        title: "BLX",
        total: 0
    },
    couponData: JSON.parse(dataCouponCode) ?? [],
    couponDiscount: 0,
    productSearched: []
}

const UseInitialState = () => {
    const [state, setState] = React.useState(initialState);
    const [cartModal, setCartModal] = React.useState({payload: false});
    const [menuMobile, setMenuMobile] = React.useState({payload: false});
    const [loginModal, setLoginModal] = React.useState({payload: false});
    const [registerModal, setRegisterModal] = React.useState({payload: false});

    const addToCart = (payload) => {
        // Verifica si el producto ya está en el carrito
        const productInCart = state.cart.find(item => item.wordpress_id === payload.wordpress_id);
    
        let newCart;
    
        if (productInCart) {
            // Si el producto ya está en el carrito, aumenta su cantidad
            newCart = state.cart.map(item => {
                if (item.wordpress_id === payload.wordpress_id) {
                    console.log(payload.quantity);
                    if (item.quantity < item.stock_quantity && (item.quantity + payload.quantity) <= item.stock_quantity) {
                        console.log(item.quantity + payload.quantity);
                        return {
                            ...item,
                            quantity: item.quantity + payload.quantity
                        };
                    }else {
                        return {
                            ...item,
                            quantity: item.quantity + 0
                        }; 
                    }
                } else {
                    return item;
                }
            });
        } else {
            // Si el producto no está en el carrito, lo agrega
            newCart = [...state.cart, payload];
        }
    
        // Actualiza el estado y el localStorage
        setState({
            ...state,
            cart: newCart
        });
        localStorage.setItem("products_cart", JSON.stringify(newCart));
    };
    const modalCart = (payload) => {
        setCartModal({
            ...cartModal,
           payload
        });
    };
    const modalMenuMobile = (payload) => {
        setMenuMobile({
            ...menuMobile,
           payload
        });
    };

    const modalLogin = (payload) => {
        setLoginModal({
            ...loginModal,
           payload
        });
    };
    const modalRegister = (payload) => {
        setRegisterModal({
            ...registerModal,
           payload
        });
    };

    const removeFromCart = (payload) => {
        const newCart = [...state.cart];
        const filterCart = newCart.filter(items => items.wordpress_id !== payload.wordpress_id);
        localStorage.setItem("products_cart", JSON.stringify(filterCart));
        setState({
            cart: filterCart,
        });
        window.localStorage.removeItem('coupon_discount');
    }
    const getProductBySlug = (payload) => {
        setState({
            ...state,
            product: [...state.product, payload]
        });
    }
    const addCheckoutData = (payload) => {
        setState({
            ...state,
            billingCheckout: payload
        });
        console.log(state.billingCheckout)
        localStorage.setItem("billing_checkout", JSON.stringify(payload));
    };
    const addCouponData = (payload) => {
        setState({
            ...state,
            couponData: payload
        });
        // console.log(state.billingCheckout)
        localStorage.setItem("all_data_coupon", JSON.stringify(payload));
    };
    const addCouponDiscount = (payload) => {
        setState({
            ...state,
            couponDiscount: payload
        });
        console.log(state.couponDiscount)
        localStorage.setItem("coupon_discount", JSON.stringify(payload));
    };
    const addUserLogin = (payload) => {
        setState({
            ...state,
            user: payload
        });
        console.log(state.user)
        localStorage.setItem("user_login", JSON.stringify(payload));
    };
    const addShippingCost = (payload) => {
        const shippingCostAsInteger = Number(Number(payload.total).toFixed(0));
        setState({
            ...state,
            shippingLines: {
                title: payload.title,
                total: shippingCostAsInteger
            }
        });
        console.log(state.shippingCost)
        // localStorage.setItem("user_login", JSON.stringify(payload));
    };
    const addProductSearched = (payload) => {
        setState({
            ...state,
            productSearched: payload
        });
        console.log(state.productSearched)
    };

    const updateStockInCart = (payload) => {
        // Haz una copia del carrito actual
        const updatedCart = [...state.cart];
    
        // Encuentra el producto en el carrito por su ID y actualiza su stock
        const productToUpdate = updatedCart.find(item => item.wordpress_id === payload.wordpress_id);
        if (productToUpdate) {
            productToUpdate.quantity = payload.quantity;
        }
        console.log("Before update: ", updatedCart);
        console.log("Product to update: ", productToUpdate);
        // Actualiza el estado del carrito con la nueva información
        setState({
            ...state,
            cart: updatedCart
        });
        // Actualiza el localStorage con la nueva información del carrito
        localStorage.setItem("products_cart", JSON.stringify(updatedCart));
    }

    return {
        state,
        cartModal,
        loginModal,
        registerModal,
        menuMobile,
        addToCart,
        modalCart,
        removeFromCart,
        getProductBySlug,
        modalLogin,
        modalRegister,
        addCheckoutData,
        addUserLogin,
        addShippingCost,
        addCouponData,
        addCouponDiscount,
        addProductSearched,
        modalMenuMobile,
        updateStockInCart
    }
}

export default UseInitialState;
